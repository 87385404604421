import React from 'react';
import { OnDemandWebinarViewContext, TemplateProps } from '../parser';
import { PathService } from '../services';
import { OnDemandWebinarView } from '../views';

const OnDemandWebinar: React.FunctionComponent<TemplateProps<
  OnDemandWebinarViewContext
>> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return (
    <OnDemandWebinarView
      page={JSON.parse(pageContext.page)}
      content={JSON.parse(pageContext.content)}
    />
  );
};

export default OnDemandWebinar;
